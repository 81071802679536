.Footer {
  width: 100vw;
  background-color: black;
  &-text {
    font-size: 32px;
    font-family: "Bricolage Grotesque", sans-serif;
    color: white;
    padding-top: 16px;
    padding-bottom: 8px;
    text-transform: uppercase;
  }
}
