.ImageCard {
  display: block;
  max-width: 400px;
  max-height: 232x;
  width: auto;
  height: auto;
  align-items: center;
  img {
    width: 100%;
  }

  @media screen and (max-width: 1200px) {
    max-width: 200px;
    max-height: 232x;
  }
  @media screen and (max-width: 600px) {
    max-width: 100px;
    max-height: 232x;
  }
}
