.artworks {
  height: 60vh;
  overflow-y: hidden;
  //   width: 1440px;
  margin: 0 auto;
  position: relative;
  //

  &-note {
    min-height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 600;
    font-weight: 600;
    font-style: normal;
    display: flex;
    flex-direction: column;
    background-color: #feff9c;
    padding: 56px 24px;
    box-shadow: 9px 0px 10px -5px rgba(0, 0, 0, 0.42);
    align-items: center;
    &-action {
      margin-top: 20px;
    }

    &::before {
      content: "";
      background-color: rgba(222, 229, 22, 0.5);
      width: 160px;
      height: 45px;
      position: absolute;
      top: -20px;
      right: 30%;

      transform: translateX(0%) rotate(-6deg);
    }
    // &::after {
    //   content: "";
    //   left: 0;
    //   bottom: 0;
    //   border-top-color: #ffffff 20px solid;
    //   border-right-color: #ffffff;
    //   border-bottom-color: #ffffff;
    //   border-left-color: #4100da;
    //   background-color: rgb(255, 208, 0);
    //   position: absolute;
    //   width: 40px;
    //   height: 40px;
    //   z-index: 10;
    // }
    max-width: 460px;
  }
  &-title {
    font-size: 48px;
    font-weight: 600;
    font-family: "Bricolage Grotesque", sans-serif;
    margin-bottom: 32px;
    // margin-top: 24px;
  }
  &-para {
    font-size: 18px;
    font-weight: 600;
    font-family: "Handlee", serif;
    line-height: 150%;
    text-align: left;
    margin-bottom: 12px;
  }
}
