@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.Accordion {
  &-container {
    display: flex;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
  }
  &-item {
    width: 100%;
    &:hover {
    }
  }
  &-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: rgba(255, 255, 255, 0);
    border: 0px solid white;
    border-bottom: 1px solid black;

    &:hover {
      // background-color: #eeeded;
      border-radius: 12px;
      // border: 1px solid black;
      cursor: pointer;
    }
  }
  &-header {
    width: 100%;
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &-body {
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    padding: 8px 24px;
    border-bottom: 1px solid black;
    padding-bottom: 64px;
    // background-color: #e3e8f0;
    // margin-bottom: 20px;
  }
  &-metadata {
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    display: flex;
    gap: 24px;

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }

  &-content {
    max-width: 900px;
  }
}

.icon {
  height: 24px;
  width: 24px;
}

.Accordion-item {
  position: relative;

  .Accordion-button {
    .image {
      display: none;
      // height: 120%;
      max-height: 300px;
      overflow: hidden;
      border-radius: 12px;
      transition: all 0.2s linear;
      position: absolute;
      right: 10%;
      top: -200px;
    }
    &:hover {
      .image {
        display: block;
      }
    }
  }
}

.Slider {
  padding-top: 24px;
  position: relative;

  &-action {
    display: flex;
    gap: 16px;
    &-icon {
      height: 32px;
      width: 32px;
    }
    &-button {
      padding: 4px;
      background-color: transparent;
      border: none;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
  &-body {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    gap: 32px;
    overflow-y: hidden;
    overflow-x: hidden;
  }
}

.Slide {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  max-width: 400px;
  min-width: 400px;

  &-Title {
    margin-top: 18px;
    margin-bottom: 4px;
    font-size: 18px;
  }

  &-content {
    margin-bottom: 8px;
    font-size: 18px;
  }

  & img {
    width: 100%;
  }
}
