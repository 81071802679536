.navigation {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  top: 20px;
  z-index: 1000;
  margin: 0 auto;

  .nav-content {
    background-color: rgba(255, 255, 255, 0.87);
    border-radius: 50px;
    padding: 0.5rem;
    display: flex;
    gap: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(84px);
    border: 0.5px solid #ffffff29;

    .nav-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      text-decoration: none;
      color: #000;
      border-radius: 25px;
      transition: all 0.3s ease;
      font-family: "DM Sans", sans-serif;
      font-weight: 600;
      font-size: 1rem;

      &:hover {
        background: #000;
        color: white;
      }

      &.active {
        background: #000;
        color: white;
      }

      .nav-icon {
        font-size: 1.25rem;
      }
    }

    .resume-btn {
      border: 1px solid #000;
      background: transparent;
      cursor: pointer;

      &:hover {
        background: #000;
        color: white;
      }
    }
  }

  //   &.dark-mode {
  //     .nav-content {
  //       background: #333;
  //       color: #fff;

  //       .nav-item {
  //         color: #fff;

  //         &:hover {
  //           background: #444;
  //         }

  //         &.active {
  //           background: #fff;
  //           color: #333;
  //         }
  //       }

  //       .resume-btn {
  //         border-color: #fff;

  //         &:hover {
  //           background: #fff;
  //           color: #333;
  //         }
  // }
  //     }

  // Media query for mobile devices
  @media screen and (max-width: 640px) {
    .navigation {
      top: 10px;
    }
    .nav-text {
      display: none;
    }
    .navigation .nav-content {
      .nav-item {
        padding: 0.5rem;
      }
      .nav-text {
        display: none;
      }

      .resume-btn {
        display: none;
      }
    }
  }
}
