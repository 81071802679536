.Otherwork {
  background-color: white;
}
.Otherwork-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: white;
  margin: 0 auto;
  max-width: 1440px;
  gap: 36px;
  padding-top: 80px;
}
@media screen and (max-width: 720px) {
  .Otherwork-container {
    gap: 16px;
    padding-top: 40px;
  }
}
.Otherwork-action {
  width: 100%;
  height: 120px;
  position: relative;
  z-index: 2;
  top: -120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;
  background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0.38) 80%, rgba(255, 255, 255, 0) 100%);
}
@media screen and (max-width: 1200px) {
  .Otherwork-action {
    height: 100px;
    top: -60px;
  }
}/*# sourceMappingURL=OtherWork.css.map */