.case-study-item {
  color: #000;
  background-color: #fff;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
}
.case-study-item .slider-image img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.case-study-item .case-study-content {
  text-align: left;
  margin-bottom: 24px;
  max-width: 768px;
  margin: 48px auto;
}/*# sourceMappingURL=CasestudyItem.css.map */