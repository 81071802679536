.Hero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  // max-width: 1600px;
  justify-content: center;
  min-height: 70vh;
  gap: 100px;
  padding: 4rem 0;
  background-color: #ffffff;
  background-image: radial-gradient(
    #aea9a9 0.6000000000000001px,
    #ffffff 0.6000000000000001px
  );
  background-size: 16px 16px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px;
    gap: 64px;
  }
  &-link {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.75rem 24px;
    border: none;
    font-size: 1rem;
    min-height: 40px;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid black;
    border-radius: 100px;
    background-color: rgb(255, 255, 255);
    // color: white;
    cursor: pointer;
    gap: 8px;
    &:hover .marquee__inner {
      // opacity: 0.7;
      // background-image: linear-gradient(120deg, #fbc2eb 0%, #a6c1ee 100%);
      // // background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%);
      // outline: none;
      // border: 1px solid #a6c1ee;
      // color: rgb(0, 0, 0);
      // transition: 200ms ease-in;
      animation-play-state: running;
      opacity: 1;
      transition-duration: 0.4s;
      .Hero-contentCTAText {
        display: none;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &-contentImg {
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: "About Me";
      display: flex;
      position: absolute;
      background-image: linear-gradient(
        120deg,
        rgba(251, 194, 235, 0.7) 0%,
        #a6c1ee 100%
      );
      height: 150px;
      width: 150px;
      border-radius: 50%;
      margin: auto auto;
      justify-content: center;
      color: rgb(0, 0, 0);
      align-items: center;
      display: none;
      opacity: 0;
    }
    &:hover::after {
      display: flex;
      opacity: 1;
      transition: opacity 2s ease-in;
    }
    & .rotating_text {
      z-index: 200;
      animation-iteration-count: infinite;
      animation-duration: 5s;
      animation-timing-function: linear;
      @media screen and (max-width: 720px) {
        height: 200px;
        width: 200px;
      }
      &:hover {
        animation-name: rotate-s-loader;
        cursor: pointer;
        .Hero-contentImg::after {
          display: flex;
        }
      }
    }
    & .dev_image {
      position: absolute;
      @media screen and (max-width: 720px) {
        height: 120px;
        width: 120px;
      }
    }
  }
  &-contentHeading {
    font-size: 8vh;
    font-family: "Bricolage Grotesque", sans-serif;
    position: relative;
    z-index: 1;
    line-height: 114%;
    margin: 0;
    text-align: left;
  }
  &-contentPara {
    max-width: 600px;
    margin-bottom: 1.25rem;
    font-size: 22px;
    text-align: left;
    font-weight: 500;
    line-height: 160%;
  }

  &-contentCTA {
    display: flex;
    flex-direction: row;
    & .customButton {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
  &-slides {
    margin-right: 0;
    height: 648px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      height: auto;
      width: 100%;
    }
  }
  &-externallink {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }

  &-slidesImage {
    height: 90%;
    width: 90%;
    overflow: hidden;
    border-radius: 12px;
    transition: all 0.2s linear;

    &:hover {
      scale: 1.1;
    }
    @media only screen and (max-width: 1024px) {
      height: 100%;
      width: 100%;
    }
  }
}

@keyframes rotate-s-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.marquee {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
}

.marquee__inner {
  display: flex;
  flex-direction: row;
  position: relative;

  animation: marquee 1s linear infinite;
  animation-play-state: paused;
  opacity: 0;
  z-index: 12;
  // background-image: linear-gradient(120deg, #eceef0 0%, #eef2f4 100%);
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.75rem 24px;
  border: none;
  font-size: 1rem;
  min-height: 40px;
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid rgb(0, 0, 0);

  border-radius: 100px;
}
.marquee__text {
  padding-right: 8px;
  transform: rotate(50deg);
  columns: white;
}
