.gradient {
  position: absolute;
  top: -60px;
  left: 30%;
  z-index: 10;
  overflow: hidden;
}
@media only screen and (max-width: 1024px) {
  .gradient {
    top: -5px;
    left: 2%;
    width: 280px;
  }
}/*# sourceMappingURL=TopGradient.css.map */