.gallery-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.gallery-wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.polaroid-about {
  position: relative;
  padding: 1.2rem;
  transition: transform 0.3s ease-out;
  width: 16rem !important;
  height: 18rem !important;
}
.polaroid-about .polaroid-content {
  position: relative;
}
.polaroid-about .polaroid-content img {
  width: 16rem;
  height: 18rem;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.polaroid-about.polaroid-2 {
  top: 35%;
  left: 55%;
}
.polaroid-about.polaroid-3 {
  top: 55%;
  right: 35%;
}
.polaroid-about.polaroid-4 {
  top: 55%;
  left: 55%;
}
.polaroid-about:hover {
  z-index: 10;
}/*# sourceMappingURL=pg.css.map */