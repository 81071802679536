.section-navigator {
  position: fixed;
  right: 1rem;
  top: 25%;
  width: 16rem;
  background-color: white;
  padding: 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 70vh;
  overflow-y: auto;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  text-align: left;
  z-index: 100;
  z-index: 1000;
}
.section-navigator--visible {
  opacity: 1;
  transform: translateX(0);
}
.section-navigator--collapsed {
  width: 200px;
}
.section-navigator--collapsed .section-navigator__header {
  margin-bottom: 0;
}
.section-navigator--collapsed .section-navigator__title {
  display: block;
}
.section-navigator--collapsed .section-navigator__toggle {
  margin-left: auto;
}
.section-navigator::-webkit-scrollbar {
  width: 6px;
}
.section-navigator::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}
.section-navigator::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}
.section-navigator::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.section-navigator__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  min-height: 24px;
}
.section-navigator__title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #000000;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.section-navigator__toggle {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1;
  color: #4b5563;
  transition: all 0.2s ease;
  flex-shrink: 0;
}
.section-navigator__toggle:hover {
  background-color: #f3f4f6;
  color: #2563eb;
}
.section-navigator__toggle:focus {
  outline: 2px solid #2563eb;
  outline-offset: 2px;
}
.section-navigator__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: all 0.3s ease;
  opacity: 1;
  overflow-y: auto;
  max-height: calc(70vh - 3rem);
  font-family: "DM Sans", sans-serif;
}
.section-navigator__list--hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
}
.section-navigator__item--level-1 {
  margin-left: 0;
}
.section-navigator__item--level-2 {
  margin-left: 1rem;
}
.section-navigator__item--level-4 {
  margin-left: 2rem;
}
.section-navigator__button {
  width: 100%;
  text-align: left;
  padding: 0.25rem 0;
  color: #000000;
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  transition: color 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 24px;
  font-family: "DM Sans", sans-serif;
}
.section-navigator__button:hover {
  color: #033092;
}
.section-navigator__button:focus {
  outline: 2px solid #2563eb;
  outline-offset: 2px;
  border-radius: 2px;
}
@media screen and (max-width: 900px) {
  .section-navigator {
    display: none;
  }
}/*# sourceMappingURL=SectionNavigator.css.map */