.playground {
  //   background-color: black;
  display: flex;
  justify-content: center;
  padding: 64px;
  background-color: #ffffff;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;

  &-Container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
  &-img {
    height: 64px;
    width: 64px;
    margin: 0px 24px;
  }
  &-rocket {
    position: relative;
    top: 10px;
  }
  &-football {
    position: relative;
    top: 8px;
  }
}
