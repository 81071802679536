.gallery-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.gallery-wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.polaroid-about {
  position: relative;
  padding: 1.2rem;

  transition: transform 0.3s ease-out;
  width: 16rem !important; // 256px
  height: 18rem !important; // 288px

  .polaroid-content {
    position: relative;

    img {
      width: 16rem; // 256px
      height: 18rem; // 288px
      object-fit: cover;
      display: block;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
    }
  }

  // Position each polaroid
  &.polaroid-1 {
    // bottom: 200px;
    // left: 0%;
    // bottom: 80%;
  }

  &.polaroid-2 {
    top: 35%;
    left: 55%;
  }

  &.polaroid-3 {
    top: 55%;
    right: 35%;
  }

  &.polaroid-4 {
    top: 55%;
    left: 55%;
  }

  // Add hover effect
  &:hover {
    z-index: 10;
  }
}

// Add subtle texture to background
