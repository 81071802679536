.Auth {
  padding-top: 120px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-lockImg {
    height: 72px;
    width: 72px;
    margin-bottom: 12px;
  }

  &-title {
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    font-weight: 600;
  }
  &-message {
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    margin-top: 12px;
    max-width: 480px;
  }

  &-input {
    padding: 8px 16px;
    border-radius: 24px;
    font-family: DM Sans;
    font-size: 16px;
  }

  &-action {
    display: flex;
    gap: 12px;
    margin-top: 20px;
  }
  &-button {
    display: flex;
    padding: 8px 16px;
    min-width: 100px;
    align-items: center;
    justify-content: center;
    border-radius: 64px;
    color: white;
    background-color: black;
    font-size: 16px;
    font-family: DM Sans;
    font-weight: 600;
    cursor: pointer;
    border: 1px soild;
    &:hover {
      opacity: 0.8;
    }
  }
  &-error {
    color: red;
    margin-top: 8px;
  }
}
