.customButton {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 200px;
  font-size: 1rem;
  min-height: 40px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;

  &--primary {
    background-color: black;
    color: white;
  }
  &--secondary {
    background-color: #dee8f1;
    color: black;
  }
}
