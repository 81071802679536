.Expcard {
  display: flex;
  flex-direction: column;
  stroke: 1px solid red;
  min-width: 100px;
  background-color: red;
  max-width: 22%;
  padding: 0 24px 24px 24px;
  border-radius: 24px;
  @media only screen and (max-width: 1376px) {
    max-width: 48%;
  }
  @media only screen and (max-width: 800px) {
    max-width: 80%;
  }

  &-title {
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 32px;
  }

  &-content {
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 12px;
  }

  &--grey {
    background: #f9fafb;
    border: 1px solid #e5e5e5;
  }
  &--blue {
    background-color: blue;
  }
}
