.abhero-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  gap: 48px;
  padding: 0px 0 24px;
  background-color: #ffffff;
  background-image: radial-gradient(#aea9a9 0.6px, #ffffff 0.6px);
  background-size: 16px 16px;
}
@media only screen and (max-width: 1024px) {
  .abhero-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px;
    gap: 64px;
    text-align: left;
  }
}
.abhero-title {
  font-size: 6vh;
  font-family: "Bricolage Grotesque", sans-serif;
  position: relative;
  z-index: 1;
  line-height: 114%;
  margin: 0;
}
.abhero-description {
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 900px;
  margin-top: 20px;
}
@media only screen and (max-width: 1440px) {
  .abhero-description {
    text-align: left;
  }
}/*# sourceMappingURL=AboutHero.css.map */