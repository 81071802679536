@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
.polaroids-container {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f3f4f6;
  padding: 2rem;
}

.polaroids-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  padding: 2rem;
}

.polaroid-wrapper {
  perspective: 1000px;
}
.polaroid-wrapper.dragging {
  cursor: grabbing;
}

.polaroid {
  position: relative;
  width: 100%;
  min-height: 320px;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  margin-bottom: 24px;
}

.polaroid-front,
.polaroid-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.polaroid-front {
  background-color: white;
  padding: 1rem;
}
.polaroid-front img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 1rem;
}
.polaroid-front h3 {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  font-family: "Handlee", serif;
  font-weight: 600;
  font-style: normal;
}

.polaroid-back {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}
.polaroid-back p {
  color: rgb(0, 0, 0);
  text-align: center;
}

@media (max-width: 768px) {
  .polaroids-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 3rem;
  }
}/*# sourceMappingURL=ScatteredPolaroids.css.map */