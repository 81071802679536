@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,400;12..96,600&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "DM Sans", "helvetica", "arial", sans-serif;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 500;
  background-color: #ffffff;
  overflow-x: hidden !important;
  text-decoration: none;
}
body ::-moz-selection {
  background-color: #a8cbe9;
}
body ::selection {
  background-color: #a8cbe9;
}

.Caption {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.42em;
  text-transform: uppercase;
}

.Title {
  font-family: DM Sans;
  font-size: 64px;
  font-weight: 500;
  line-height: 85px;
  letter-spacing: 0em;
}
@media only screen and (max-width: 1024px) {
  .Title {
    font-size: 42px;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: 0em;
  }
}

.Mono {
  font-family: DM Mono;
  font-weight: 500;
}

.Display {
  font-family: Bricolage Grotesque;
  font-weight: 600;
}

.cs-footer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 960px;
  padding-top: 24px;
  gap: 48px;
  padding: 48px 0px;
  text-align: left;
  justify-items: center;
  justify-content: center;
  align-items: center;
}
.cs-footer-title {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  width: 100%;
  text-align: center;
}
.cs-footer-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
}
.cs-footer-img {
  width: 100%;
}
.cs-footer-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.cs-footer-card-title {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  margin-bottom: 20px;
  margin: 0 auto;
}
.cs-footer-card:hover {
  cursor: pointer;
  text-decoration: underline;
}
.cs-contentimg {
  width: 80%;
  max-width: 1200px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s ease-in-out;
}
.cs-contentimg:hover {
  opacity: 0.5;
}
.cs-title {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 130%;
  letter-spacing: 0%;
  margin-bottom: 20px;
  padding-bottom: 12px;
  margin-top: 160px;
}
.cs-description {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  color: rgb(0, 0, 0);
  margin-bottom: 24px;
}
.cs-description:last-child {
  margin-bottom: 0px;
}
.cs-subtitle {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 24px;
}
.cs-li {
  font-family: DM Sans;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0%;
  margin-bottom: 12px;
}
.cs-alttext {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: rgb(73, 73, 73);
  margin: 20px auto;
}
.cs-headername {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0%;
  margin-bottom: 24px;
}
.cs-hero {
  width: 90%;
}
.cs-whitestrip {
  background-color: white;
  margin: -50px;
  height: 49px;
  position: relative;
  z-index: 12;
}
.cs-iframe-container {
  position: relative;
}
.cs-iframe-annotate {
  position: absolute;
  z-index: 1000;
  width: 240px;
  left: 70%;
}
.cs-iframe {
  width: 70%;
  max-width: 1200px;
  height: 80vh;
  border: none;
  max-height: 800px;
}
.cs-iframe::before {
  position: absolute;
  z-index: 1000;
  background-image: "https://storage.googleapis.com/dv-portfolio-assets/zoom%20instruction.svg";
}
.cs-summary {
  background-color: #e5effd;
  border: 1 px solid #93c5fd;
  padding: 8px 24px 24px;
  border-radius: 8px;
}
.cs-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.cs-feature {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 48px auto;
  justify-content: center;
  gap: 48px;
}
@media screen and (max-width: 1680px) {
  .cs-feature {
    flex-direction: column;
  }
}
.cs-feature-content {
  min-width: 400px;
  text-align: left;
  max-width: 480px;
}
.cs-feature-img {
  width: 80%;
  transition: 0.3s ease-in-out;
}
.cs-feature-img:hover {
  opacity: 0.5;
}
.cs-header-actions {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  margin-top: 24px;
}
.cs-header-button {
  display: flex;
  align-items: center;
  padding: 0.75rem 24px;
  border-radius: 200px;
  font-size: 1rem;
  min-height: 40px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  background-color: black;
  color: white;
  cursor: pointer;
  gap: 8px;
}
.cs-header-button:hover {
  opacity: 0.8;
}
.cs-header-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 24px;
  border: none;
  font-size: 1rem;
  min-height: 40px;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  gap: 8px;
}
.cs-header-link:hover {
  opacity: 0.7;
}/*# sourceMappingURL=index.css.map */