.case-study-item {
  color: #000;
  background-color: #fff;
  padding: 20px;
  margin: 0 auto;
  width: 100%;

  // margin-top: 96px;

  .slider-image {
    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .case-study-content {
    text-align: left;
    margin-bottom: 24px;
    max-width: 768px;

    margin: 48px auto;

    // h1 {
    //   font-family: "DM Sans", sans-serif;
    //   font-weight: 500;
    //   font-size: 48px;
    //   line-height: 120%;
    //   letter-spacing: 0%;
    //   margin-bottom: 24px;
    // }

    // p {
    //   font-family: "DM Sans", sans-serif;
    //   font-weight: 400;
    //   font-size: 20px;
    //   line-height: 28px;
    // }

    // h2 {
    //   font-family: "DM Sans";
    //   font-weight: 500;
    //   font-size: 32px;
    //   line-height: 38.4px;
    //   letter-spacing: 0%;
    //   margin-bottom: 24px;
    // }

    // p {
    //   font-family: "DM Sans", sans-serif;
    //   font-weight: 400;
    //   font-size: 20px;
    //   line-height: 28px;
    //   color: rgba(73, 73, 73, 1);
    //   margin-bottom: 24px;
    // }
  }
}

// .alttext {
//   font-family: "DM Sans", sans-serif;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 28px;
//   color: rgba(73, 73, 73, 1);
//   margin: 20px auto;
// }
