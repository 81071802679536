@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap"); // ScatteredPolaroids.scss

.polaroids-container {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f3f4f6;
  padding: 2rem;
}

.polaroids-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  padding: 2rem;
}

.polaroid-wrapper {
  perspective: 1000px;
  //   cursor: pointer;

  &.dragging {
    cursor: grabbing;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: -10px;
    //   right: -10px;
    //   width: 20px;
    //   height: 20px;
    //   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M15 3h6v6M14 10l6.1-6.1M9 21H3v-6M10 14l-6.1 6.1'/%3E%3C/svg%3E");
    //   background-repeat: no-repeat;
    //   background-size: contain;
    // }
  }
}

.polaroid {
  position: relative;
  width: 100%;
  min-height: 320px;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  margin-bottom: 24px;

  //   .polaroid-wrapper:hover & {
  //     transform: rotateY(180deg);
  //   }
}

.polaroid-front,
.polaroid-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.polaroid-front {
  background-color: white;
  padding: 1rem;

  img {
    width: 100%;
    height: 100%;
    // height: 224px;
    object-fit: contain;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    font-family: "Handlee", serif;
    font-weight: 600;
    font-style: normal;
  }
}

.polaroid-back {
  // Red background for visibility
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;

  p {
    color: rgb(0, 0, 0);
    text-align: center;
  }
}

@media (max-width: 768px) {
  .polaroids-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 3rem;
  }
}
