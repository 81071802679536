.navigation {
  width: 100vw;
  display: flex;
  justify-content: center;
  padding: 1rem;
  position: fixed;
  top: 20px;
  z-index: 1000;
  margin: 0 auto;
}
.navigation .nav-content {
  background-color: rgba(255, 255, 255, 0.87);
  border-radius: 50px;
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  -webkit-backdrop-filter: blur(84px);
          backdrop-filter: blur(84px);
  border: 0.5px solid rgba(255, 255, 255, 0.1607843137);
}
.navigation .nav-content .nav-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #000;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;
  font-size: 1rem;
}
.navigation .nav-content .nav-item:hover {
  background: #000;
  color: white;
}
.navigation .nav-content .nav-item.active {
  background: #000;
  color: white;
}
.navigation .nav-content .nav-item .nav-icon {
  font-size: 1.25rem;
}
.navigation .nav-content .resume-btn {
  border: 1px solid #000;
  background: transparent;
  cursor: pointer;
}
.navigation .nav-content .resume-btn:hover {
  background: #000;
  color: white;
}
@media screen and (max-width: 640px) {
  .navigation .navigation {
    top: 10px;
  }
  .navigation .nav-text {
    display: none;
  }
  .navigation .navigation .nav-content .nav-item {
    padding: 0.5rem;
  }
  .navigation .navigation .nav-content .nav-text {
    display: none;
  }
  .navigation .navigation .nav-content .resume-btn {
    display: none;
  }
}/*# sourceMappingURL=Navigation.css.map */