.projects-collection {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 120px auto;
  padding: 40px 20px;
}
.projects-collection .project-wrapper {
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .projects-collection .project-wrapper {
    margin-bottom: 60px;
  }
}
.projects-collection .project-divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 60px 0;
}
@media (max-width: 768px) {
  .projects-collection .project-divider {
    margin: 40px 0;
  }
}

.project-container {
  display: flex;
  width: 100%;
  gap: 40px;
  text-align: left;
}
@media (max-width: 768px) {
  .project-container {
    flex-direction: column;
    gap: 24px;
  }
}
.project-container .project-info {
  width: 30%;
  padding-top: 16px;
}
@media (max-width: 768px) {
  .project-container .project-info {
    width: 100%;
    padding-bottom: 16px;
  }
}
.project-container .project-info .project-caption {
  font-size: 14px;
  color: #3b3b3b;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
.project-container .project-info .project-title {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 16px 0;
  color: #333;
  line-height: 1.2;
}
.project-container .project-info .project-description {
  font-size: 18px;
  line-height: 1.6;
  color: #000000;
}
.project-container .project-masonry {
  flex: 1;
  display: flex;
  gap: 24px;
}
@media (max-width: 768px) {
  .project-container .project-masonry {
    gap: 16px;
  }
}
.project-container .project-masonry .masonry-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 768px) {
  .project-container .project-masonry .masonry-column {
    gap: 16px;
  }
}
.project-container .project-masonry .masonry-item {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.project-container .project-masonry .masonry-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}
.project-container .project-masonry .masonry-item .masonry-image {
  width: 100%;
  height: auto;
  display: block;
}
.project-container .project-masonry .masonry-item .image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  color: white;
  font-size: 16px;
}/*# sourceMappingURL=OldProjectCollection.css.map */