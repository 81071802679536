.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  gap: 24px;
  margin: 0 auto;

  font-family: "DM Sans", "helvetica", "arial", sans-serif;

  &-container {
    margin: 80px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 32px;
    max-width: 1200px;

    @media only screen and (max-width: 800px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 32px;
      gap: 64px;
      text-align: left;
    }
  }
}
.left-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
}
.testimonaial-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 50%;
  gap: 64px;
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
}

.testimonial-card {
  background-color: #f8f9fc;
  border-radius: 16px;
  padding: 32px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
}

.quote {
  font-size: 20px;
  line-height: 1.6;
  color: #1a1a1a;
  margin-bottom: 24px;
  flex-grow: 1;
  font-family: "DM Sans", "helvetica", "arial", sans-serif;
  text-align: left;
}

.author-info {
  margin-bottom: 16px;
  font-family: "DM Sans", "helvetica", "arial", sans-serif;

  .details {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: "DM Sans", "helvetica", "arial", sans-serif;
    align-items: flex-start;
  }

  .author-name {
    font-size: 18px;
    font-weight: 600;
    color: #1a1a1a;
    font-family: "DM Sans", "helvetica", "arial", sans-serif;
  }

  .author-role {
    font-size: 16px;
    color: #64748b;
    font-family: "DM Sans", "helvetica", "arial", sans-serif;
  }
}

.company-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .company-logo {
    height: 32px;
    object-fit: contain;
  }

  .author-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.caption {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.42em;
  text-transform: uppercase;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .testimonial-grid {
    grid-template-columns: 1fr;
    padding: 16px;
  }

  .testimonial-card {
    padding: 24px;
  }

  .quote {
    font-size: 16px;
  }
}

.exp {
  text-align: left;
  display: flex;
  flex-direction: column;

  &-cap {
    text-align: left;
  }

  &-link {
    text-align: center;
    font-weight: 600;
    width: 100%;
    color: black;
    margin-top: 20px;
    margin-bottom: 24px;
    padding: 10px 0;
    &:hover {
      text-decoration: underline;
    }
  }

  &::after {
    content: "";
    border-bottom: 1px solid rgb(179, 179, 179);
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &-title {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: rgb(0, 0, 0);
    margin-bottom: 4px;
  }
  &-year {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 32px;
    color: rgb(0, 0, 0);
    letter-spacing: 0.42em;
  }
  &-role {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    color: rgb(0, 0, 0);
    &--second {
      font-family: "DM Sans", sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: rgb(0, 0, 0);
      margin-top: 8px;
    }
    &--first {
      font-family: "DM Sans", sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: rgb(0, 0, 0);
      margin-top: 4px;
    }
  }
}
