.playground {
  display: flex;
  justify-content: center;
  padding: 64px;
  background-color: #ffffff;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
}
.playground-Container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
.playground-img {
  height: 64px;
  width: 64px;
  margin: 0px 24px;
}
.playground-rocket {
  position: relative;
  top: 10px;
}
.playground-football {
  position: relative;
  top: 8px;
}/*# sourceMappingURL=Playground.css.map */