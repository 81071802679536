.Footer {
  width: 100vw;
  background-color: black;
}
.Footer-text {
  font-size: 32px;
  font-family: "Bricolage Grotesque", sans-serif;
  color: white;
  padding-top: 16px;
  padding-bottom: 8px;
  text-transform: uppercase;
}/*# sourceMappingURL=Footer.css.map */