.Casestudy {
  &-pageheading {
    background-color: #ffffff;
    background-image: radial-gradient(
      #ceced8 0.6000000000000001px,
      #ffffff 0.6000000000000001px
    );
    background-size: 12px 12px;
    text-decoration: none;
    a,
    a:visited,
    a:hover,
    a:active {
      color: inherit;
    }
  }
  &-page {
    max-width: 1440px;
    margin: 0 auto;
  }

  &-pagecontainer {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    align-items: flex-start;
    justify-content: center;

    margin: 0 auto;
    padding: 32px;
  }
  &-pageTitle {
    font-size: 8vh;
    font-family: "Bricolage Grotesque", sans-serif;
    position: relative;
    z-index: 1;
    line-height: 114%;
    margin: 0;
    text-align: left;
  }
  &-container {
    max-width: 1280px;
    margin: 64px auto;
    display: flex;
    flex-direction: column;
    gap: 48px;
    overflow-y: hidden;
    margin-bottom: 96px;
    @media screen and (max-width: 1280px) {
      padding: 24px;
      word-wrap: break-word;
    }
    &-title {
      text-align: left;
      font-family: DM Sans;
      font-size: 24px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.42em;
      text-transform: uppercase;
    }
  }
  &-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0px 0px 48px;
    position: relative;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background-size: cover;
    a,
    a:visited,
    a:hover,
    a:active {
      color: inherit;
    }
    // background-position: center center;
    // background-repeat: repeat;
    background-color: #f2f4f6;
    border: 1px solid #dedede;
    gap: 24px;
    // border: 1px solid rgb(212, 212, 212);
    &:hover {
      cursor: pointer;
      overflow: hidden;
      // background-image: linear-gradient(to top, #cdccff 0%, #fdeaee 100%);
      // background-image: linear-gradient(
      //   to top,
      //   #d5d4d0 0%,
      //   #d5d4d0 1%,
      //   #eeeeec 31%,
      //   #efeeec 75%,
      //   #e9e9e7 100%
      // );
      background-image: linear-gradient(
        to top,
        #f3e7e9 0%,
        #e3eeff 99%,
        #e3eeff 100%
      );
      transition: all 0.2s ease-in;

      .Casestudy-item-img {
        scale: 01.02;
        overflow: hidden;
        transition: all 0.8s easie;
      }
    }
    @media screen and (max-width: 1280px) {
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 0px;
    }
    &-vertical {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: left;
      border-radius: 12px;
      border: 1px solid rgb(212, 212, 212);
      padding: 48px 0px 0px 0px;
      padding-right: 0px;
      align-items: left;
      background-color: #f2f4f6;
      border: 1px solid #dedede;
      width: 100%;
      // min-width: 400px;
      padding-bottom: 0px;
      justify-content: space-between;
      max-width: 610px;
      height: 100%;
      @media screen and (max-width: 1280px) {
        padding: 24px 0px 24px 24px;

        .Casestudy-item-img {
          scale: 1;
        }
      }
      &-wrapper {
        width: 100%;
      }
      &:hover {
        cursor: pointer;
        overflow: hidden;
        background-image: linear-gradient(
          to top,
          #f3e7e9 0%,
          #e3eeff 99%,
          #e3eeff 100%
        );
        transition: all 0.2s ease-in;

        .vertical-img {
          scale: 01.02;
          overflow: hidden;
          transition: all 0.2s ease-in;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      // max-width: 560px;
      // padding: 32px;
      max-width: 480px;
      padding-right: 24px;
    }

    &-content-vertical {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      // max-width: 560px;
      // padding: 32px;
      // max-width: 480px;
      align-items: left;
      text-decoration: none;
      padding-left: 48px;
      padding-right: 48px;
    }

    &-cap {
      font-size: 20px;
    }
    &-title {
      font-family: "DM Sans", sans-serif;
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: left;
      @media screen and (max-width: 1280px) {
        font-size: 28px;
      }
    }
    &-img {
      width: 60%;
      min-width: 40%;
      max-width: 100%;
      position: relative;
      @media screen and (max-width: 1280px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
    }
    &-description {
      font-family: "DM Sans", sans-serif;
      font-size: 24px;
      line-height: 160%;
      font-weight: 400;
      text-align: left;

      // margin-top: 16px;
      // margin-top: 8px;

      &--small {
        font-size: 20px;
        line-height: 160%;
        text-align: left;
        font-family: "DM Sans", sans-serif;
        font-weight: 400;
      }
    }
  }
}

.back-button {
  background-color: transparent;
  border: none;
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.row-work {
  display: flex;
  flex-direction: row;
  gap: 48px;
  justify-content: space-between;
  overflow: hidden;
  // flex-wrap: wrap;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
}

.Bstackwork {
  &-container {
    max-width: 1280px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 96px;
    @media only screen and (max-width: 1280px) {
      padding: 24px;
      word-wrap: break-word;
    }
  }
  &-caption {
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 6500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
  &-title {
    font-family: "DM Sans", sans-serif;
    font-size: 42px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0em;
    text-align: left;
    font-optical-sizing: auto;
    max-width: 1280px;
    position: relative;
    @media only screen and (max-width: 900px) {
      font-size: 28px;
    }
  }

  &-titlepill {
    background-color: black;
    color: white;
    padding: 4px 12px;
    margin: 8px;
    border-radius: 24px;
    font-size: 32px;
    @media only screen and (max-width: 900px) {
      padding: 4px 12px;
      font-size: 24px;
    }
  }
}
.arrow {
  margin-left: 8px;
  margin-right: 52px;
  width: 48px;
  @media only screen and (max-width: 900px) {
    margin: 0;
  }

  &-icon {
    position: absolute;
    top: 4px;
    @media only screen and (max-width: 900px) {
      height: 48px;
      top: 0px;
      display: none;
    }
  }
}
// .key {
//   margin-left: 70px;
// }

.patternone {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.top-gradient {
  position: absolute;
  top: -40px;
  left: 30%;
}

.column-work {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 48px;
}

.small-card {
  padding-bottom: 48px;
}

.contributions {
  &-fullwidth {
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 6px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 24px auto;
    width: 100%;
  }
  &-title {
    font-size: 20px;
    color: rgb(52, 52, 52);
    font-weight: 500;
  }
}

.gradient-bg {
  background-image: linear-gradient(
    120deg,
    #f3eae6 0%,
    #f0e7e4 20%,
    #ace0f9 100%
  );
  &:hover {
    background-image: linear-gradient(
      120deg,
      #f3eae6 0%,
      #f0e7e4 20%,
      #ace0f9 100%
    );
    cursor: auto;
  }
}
