.slider-wrapper {
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 0;
}
.slider-wrapper .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  touch-action: pan-y;
}
.slider-wrapper .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slider-wrapper .slick-list:focus {
  outline: none;
}
.slider-wrapper .slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.slide {
  padding: 0 0px;
}
.slide-content {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  transition: all 0.5s cubic-bezier(0.87, 0.03, 0.41, 0.9);
  aspect-ratio: 1;
}
.slide-content:hover {
  transform: scale(0.95);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}
.slide-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: all 0.3s ease;
}
.slick-arrow:before {
  color: #333;
  font-size: 20px;
}
.slick-arrow:hover {
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
.slick-arrow.slick-prev {
  left: -20px;
}
.slick-arrow.slick-next {
  right: -20px;
}

@media (max-width: 1024px) {
  .slider-wrapper {
    max-width: 800px;
  }
}
@media (max-width: 640px) {
  .slider-wrapper {
    max-width: 400px;
  }
  .slide {
    padding: 0 4px;
  }
  .slick-arrow {
    display: none !important;
  }
}
.slick-slide {
  transition: all 0.5s cubic-bezier(0.87, 0.03, 0.41, 0.9);
}
.slick-slide.slick-active {
  opacity: 1;
}

.slider-title {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 42px;
  font-weight: 500;
  line-height: 85px;
  letter-spacing: 0em;
}
@media only screen and (max-width: 1024px) {
  .slider-title {
    font-size: 42px;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: 0em;
  }
}
.slider-description {
  font-family: "DM Sans", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 1000px;
  margin-top: 20px;
}
@media only screen and (max-width: 1440px) {
  .slider-description {
    text-align: left;
  }
}
.slider-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 48px auto;
  max-width: 1000px;
}/*# sourceMappingURL=Slider.css.map */